<template>
    <div class="uv-page">
        <v-tabs-items class="mb-10" v-model="tab" v-if="taxonomy">
            <!-- Concepts sheet -->
            <v-tab-item :value=0
                tile
            >
                <v-card flat tile color="primary">
                    <v-data-table v-for="(presentation, pIdx) in taxonomy['Concepts']" :key="`pre_${pIdx}`"
                        dense
                        selectable
                        :id="pIdx == (taxonomy['Concepts'].length -1) ? 'last' : undefined"
                        hide-default-footer
                        :item-class="itemClass"
                        disable-pagination
                        :headers="headers(presentation)"
                        :items="presentation.rows"
                        class="elevation-4 mt-2"
                        v-model="presentation.selectedRows"
                        show-select
                    >
                    <template #top>
                        <v-container fluid>
                            <v-layout>
                                <v-btn tile text color="primary" class="text-none pa-0" v-show="!presentation.edit"
                                    @click="$set(presentation , 'edit', true)"
                                >
                                    {{presentation.name}}
                                </v-btn>
                                <v-text-field 
                                    v-model="presentation.name" single-line dense 
                                    v-show="presentation.edit" 
                                    @blur="$set(presentation , 'edit', false)"
                                    @change="dirty=true"></v-text-field>
                                
                                <v-spacer></v-spacer>
                                <v-btn icon v-if="presentation.selectedRows" @click="deleteRow(presentation)">
                                    <v-icon color="error">mdi-delete-variant</v-icon>
                                </v-btn>
                                <v-btn icon color="success" @click="addRow(presentation)">
                                    <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                    </template>

                    <template v-for="(h, index) in presentation.headers" v-slot:[`item.${h}`]="slotProps">
                        <v-edit-dialog :key="index" :return-value.sync="slotProps.item[h]">
                            <span v-if="h == 'label'" :class="`pl-${slotProps.item.depth * 2}`">{{slotProps.value}}</span>
                            <span v-else>{{slotProps.value}}</span>
                            <template #input>
                                <v-text-field v-model="slotProps.item[h]" single-line @change="dirty = true">
                                </v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    </v-data-table>
                </v-card>

                <v-dialog v-model="presentationDialog.visible">
                    <v-card>
                        <v-card-title class="primary--text">Type a name for the new presentation</v-card-title>
                        <v-card-text>
                            <v-text-field v-model="presentationDialog.name"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn tile text color="secondary" @click="() => {presentationDialog.visible=false; presentationDialog.name=''}">Cancel</v-btn>
                            <v-btn tile text color="primary" :disabled="!presentationDialog.name.length" @click="addPresentation">Add</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-tab-item>
            
            <!-- DTS sheet -->
            <v-tab-item :value=1
            >
                <v-data-table
                    dense
                    hide-default-footer
                    :item-class="itemClass"
                    disable-pagination
                    :headers="headers(taxonomy['DTS'])"
                    :items="taxonomy['DTS'].rows"
                    show-select
                    v-model="taxonomy['DTS'].selectedRows"
                    class="elevation-4 mb-2 pb-1"
                >
                    <template #top>
                        <v-container fluid>
                            <v-layout>
                                <v-spacer></v-spacer>
                                <v-btn icon v-if="taxonomy['DTS'].selectedRows" @click="deleteRow(taxonomy['DTS'])">
                                    <v-icon color="error">mdi-delete-variant</v-icon>
                                </v-btn>
                                <v-btn icon color="success" @click="addRow(taxonomy['DTS'])">
                                    <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                    </template>
                    <template v-for="(h, index) in taxonomy['DTS'].headers" v-slot:[`item.${h}`]="slotProps">
                        <v-edit-dialog :key="index" :return-value.sync="slotProps.item[h]">
                            {{slotProps.value}}
                            <template #input>
                                <v-text-field v-model="slotProps.item[h]" single-line @change="dirty = true">
                                </v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    </v-data-table>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog :value="!taxonomy" persistent v-else width="300">
            <v-card color="primary">
                <v-card-text>
                    <span class="white--text">Working</span>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-tabs-slider></v-tabs-slider>
        <v-footer app inset class="px-0 pt-0">
            <v-tabs centered v-model="tab" v-if="taxonomy">
                <template v-for="(val, key) in taxonomy">
                    <v-tab  :key="`tab_${key}`" class="elevation-1">
                        {{key}}
                    </v-tab>
                </template>
            </v-tabs>
        </v-footer>
    </div>
</template>

<script>
let vm;
export default {
    name: "TaxonomyEditor",
    created(){
        this.getTaxonomy();
        this.$store.commit("setPageMenu", this.pageMenu);
        vm = this;
    },
    data(){
        return{
            busy: true,
            dirty: false,
            edit: false,
            taxonomy: null,
            tab: null,
            presentationDialog: {
                visible: false,
                name: ""
            },
            pageMenu: [
                {
                    label: "Presentation",
                    icon: "mdi-plus",
                    color: "success",
                    btn: true,
                    listeners:{
                        click: ()=>{
                            this.presentationDialog.visible = true;
                        }
                    },
                },
                {
                    label: "Save",
                    icon: "mdi-content-save-outline",
                    color: "success",
                    btn: true,
                    listeners: {
                        click: () => {
                            this.save();
                        }
                    },
                    attrs:{
                        loading: false
                    },
                    visible: false
                }
            ]
        }
    },
    methods:{
        getTaxonomy(){
            this.busy = true;
            this.$apiHttp({url:"/taxonomy", params:this.$route.query})
                .then(response => {
                    if (response.data){
                        vm.taxonomy = response.data;
                        vm.dirty = false;
                    }
                })
                .finally(()=>{
                    vm.busy = false;
                });
        },
        headers(tableData){
            if (!tableData.headers){
                return;
            }
            return tableData.headers.map(function(h){
                return {
                    text: h,
                    value: h,
                    divider: true,
                    class:"header-class elevation-2",
                }    
            });
        },
        itemClass(){
            return "item-class";
        },
        addPresentation(){
            this.taxonomy["Concepts"].push({
                headers: ["label", "prefix", "name", "type", "substitutionGroup", "periodType", "balance", "abstract", "nillable", "depth", "preferredLabel", "calculation parent", "calculation weight"],
                name: this.presentationDialog.name,
                rows: [],
            });
            this.dirty = true;
            this.presentationDialog.visible = false;
            this.presentationDialog.name="";
            this.$nextTick(() => document.getElementById("last").scrollIntoView({behavior: "smooth"}));
        },
        addRow(data){
            let newRow = {};
            for(let h of data.headers){
                newRow[h] = "";
            }
            newRow.id = Math.ceil(Math.random() * Math.pow(10, 9));

            if (!data.rows){
                data.rows = [];
            }
            data.rows.push(newRow);
            this.dirty = true;
        },
        deleteRow(data){
            for (let d of data.selectedRows){
                data.rows.splice(data.rows.indexOf(d), 1);
            }
            
            for (let i = 0; i < this.taxonomy["Concepts"].length; i++){
                if (!this.taxonomy["Concepts"][i].rows.length){
                    this.taxonomy["Concepts"].splice(i, 1);
                }
            }

            this.dirty = true;
        },
        save(){
            this.busy = true;
            this.$apiHttp({
                url: "/taxonomy",
                method: "POST",
                params: this.$route.query,
                data: this.taxonomy
            })
            .then(response => {
                vm.taxonomy = response.data.data;
                vm.dirty = false;
            })
            .finally(() => {
                vm.busy = false;
            });
        }
    },
    watch:{
        dirty: function(val){
            let save = this.pageMenu.find(el => el.label=="Save");
            save.visible = val;
        },
        busy: function(val){
            let save = this.pageMenu.find(el => el.label=="Save");
            if (save.attrs){
                save.attrs.loading = val;
            }else{
                save.attrs = {loading: val};
            }
            this.$store.commit("setPageMenu", this.pageMenu);
        },
        pageMenu:{
            deep: true,
            handler: function(val){
                this.$store.commit("setPageMenu", val);
            }
           
        },
    }
}
</script>

<style scoped lang="sass">
    .v-tab
        text-transform: none

    :deep() 
        .v-tabs-slider-wrapper
            bottom: unset

        .header-class
            background-color: rgb(255, 145, 0)
            color: white !important
            text-align: center!important
            white-space: nowrap
        .item-class
            white-space: nowrap
        
        footer
            left: 56px !important
    
</style>