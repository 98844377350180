import { mapMutations } from "vuex"
export default {
    beforeDestroy() {
        //this.saveContent(true);
        this.editor.destroy()
        this.closeSideMenu()
        this.setTaxonomies([])
    },
    data(){
        return{
            reportAbortController: new AbortController(),
            taxonomiesAbortController: new AbortController(),
        }
    },
    methods: {
        ...mapMutations("xbrlData", ["setTaxonomies", "closeSideMenu"]),
        getHtmlLink() {
            if (!this.reportInfo) {
                return "#";
            }

            let htmlFile = this.reportInfo.files.filter((f) => {
                return f.type == "html";
            });

            if (htmlFile.length == 0) {
                return "#";
            }

            htmlFile = htmlFile[0];
            let link = [];
            link.push("api");
            link.push("files");
            link.push(this.reportInfo.id);
            link.push("html");
            link.push(`${htmlFile.file}`);
            return "/" + link.join("/");
        },
        getTaxonomies() {
            if (this.taxonomies?.loading){
                return;
            }
            this.setTaxonomies({...this.taxonomies, loading: true})
            this.$apiHttp({ url: `/taxonomy/packages/${this.$route.params.id}`, signal: this.taxonomiesAbortController.signal}).then((response) => {
                if (response) {
                    if(response.data.pre.length > 0) {
                        this.setTaxonomies(response.data);
                    }
                }
            }).finally( () => {
                this.setTaxonomies({...this.taxonomies, loading: false});
            });
        },
    }
}