module.exports = {
    editorWebsocket: {
        data() {
            return {
                websocket: {
                    connection: null,
                    edit_status: false,
                    reconnect: true,
                }
            }
        },
        created: function() {
            window.addEventListener("beforeunload", this.closeWs);
            this.connectWebsocket(this.$route.params.id);
        },
        destroyed: function() {
            this.closeWs();
        },
        methods: {
            connectWebsocket: function(report_id) {
                let protocol = "ws";
    
                if (window.location.protocol.includes("https")) {
                    protocol = "wss";
                }
    
                let wss = new WebSocket(
                    `${protocol}://${window.location.host}/api/ws/editor_ws/${report_id}?token=${this.$store.state.apiToken.access_token}`
                );
                this.websocket.connection = wss;
    
                let vm = this;
                wss.onopen = function() {
                };
    
                wss.onerror = function() {
                    wss.close();
                };
    
                wss.onclose = function(ev) {
                    console.log(`Editor wss ${wss.id} close with code ${ev.code}`);

                    if (!ev.wasClean && vm.websocket.reconnect) {
                        vm.$store.commit("setGlobalMessage", {
                            type: "error",
                            message: `Server disconnected. Reconnecting...`
                        });
                        vm.websocket.edit_status = false;

                        setTimeout(function() {
                            console.log(`Editor wss ${wss.id} deleted`);
                            vm.connectWebsocket(report_id);
                        }, 5000);
                    }
                }
    
                wss.onmessage = function(ev) {
                    let msg = JSON.parse(ev.data);
    
                    if (msg.alert) {
                        if (typeof msg.alert == "string"){
                            msg.alert = msg.alert.replace(/'/g, '"');
                            msg.alert = JSON.parse(msg.alert);
                        }
                        vm.$store.commit("setGlobalMessage", msg.alert);
                    }
    
                    if (msg.accept === true) {
                        vm.websocket.edit_status = true;
                        vm.websocket.connection.id = msg.reserved_id;
                        vm.editor.setOptions({editable: true})
                    } else {
                        vm.websocket.edit_status = false;
                        vm.editor.setOptions({editable: false})
                    }
                }
            },
            closeWs(){
                this.websocket.reconnect = false;
                if (this.websocket.connection){
                    this.websocket.connection.close(1000);
                    this.websocket.connection = null;
                }
                window.removeEventListener("beforeunload", this.closeWs);
            },
        }
    }
}
       
       
       
       
       