import { render, staticRenderFns } from "./TaxonomyEditor.vue?vue&type=template&id=30da0d63&scoped=true"
import script from "./TaxonomyEditor.vue?vue&type=script&lang=js"
export * from "./TaxonomyEditor.vue?vue&type=script&lang=js"
import style0 from "./TaxonomyEditor.vue?vue&type=style&index=0&id=30da0d63&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30da0d63",
  null
  
)

export default component.exports